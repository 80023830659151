import { useToast } from '@chakra-ui/react';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import {
  Connection,
  LAMPORTS_PER_SOL,
  ParsedInstruction,
  ParsedTransactionWithMeta,
  PartiallyDecodedInstruction,
  PublicKey,
} from '@solana/web3.js';
import { API, Storage } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { GetRateSolanaIDRQuery, UserProfile } from 'src/API';
import { getRateSolanaIDR } from 'src/graphql/queries';
import { useParameterStore } from './context';
import awsConfigStaging from 'src/aws-exports-dev';
import awsConfigProd from 'src/aws-exports-main';
import { GraphQLQuery } from '@aws-amplify/api';

const awsExport =
  process.env.NODE_ENV === 'production'
    ? process.env.NEXT_PUBLIC_AWS_EXPORT_TYPE == 'STAGING'
      ? awsConfigStaging
      : awsConfigProd
    : awsConfigStaging;

export const FormatNumber = (value: number | string) => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useCopyLinkToClipboard = () => {
  const toast = useToast();

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const copyLinkAddressToClipboard = () => {
    copyTextToClipboard(String(window.location.href))
      .then(() => {
        toast({
          title: `Link copied`,
          status: 'info',
          variant: 'main',
          position: 'top-right',
          isClosable: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const copyContentToClipboard = (content: string) => {
    if (content) {
      copyTextToClipboard(content)
        .then(() => {
          toast({
            title: `Content copied`,
            status: 'info',
            variant: 'main',
            position: 'top-right',
            isClosable: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return { copyLinkAddressToClipboard, copyContentToClipboard };
};

export const useBlockChainMenuList = () => {
  const blockchainMenuList = [
    {
      image: '/images/blockchain-icon/solana.svg',
      name: 'SOL',
    },
  ];

  return { blockchainMenuList };
};

export const useBalanceSol = (userProfile?: UserProfile | null) => {
  const [accInfo, setAccInfo] = useState<{
    publicKey?: string;
    balance?: number;
  }>();
  const { connectionUrl } = useParameterStore();
  const [loaded, setLoaded] = useState<boolean>(true);

  const getBalanceSol = useCallback(async () => {
    try {
      if (
        userProfile &&
        userProfile.wallets?.items[0]?.publicKey &&
        connectionUrl
      ) {
        setLoaded(false);
        const provider = new Connection(connectionUrl);
        const result = await provider.getBalance(
          new PublicKey(userProfile.wallets?.items[0]?.publicKey)
        );
        setAccInfo((i) => ({
          publicKey: userProfile.wallets?.items[0]
            ? userProfile.wallets.items[0].publicKey ?? undefined
            : undefined,
          balance: result / LAMPORTS_PER_SOL,
        }));
        setLoaded(true);
      }
    } catch (error) {
      setLoaded(true);
    }
  }, [userProfile, connectionUrl]);

  useEffect(() => {
    if (userProfile) {
      getBalanceSol();
    }
  }, [getBalanceSol, userProfile]);

  return { accInfo, getBalanceSol, loaded };
};

export const useSolExchange = () => {
  const [solToidrPrice, setSolIdrPrice] = useState<number>();

  useEffect(() => {
    const solToIdr = async () => {
      try {
        const getRate = await API.graphql<GraphQLQuery<GetRateSolanaIDRQuery>>({
          query: getRateSolanaIDR,
        });

        if (getRate.data?.getRateSolanaIDR) {
          setSolIdrPrice(getRate.data.getRateSolanaIDR.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    solToIdr();
  }, []);

  return { solToidrPrice };
};

export const useActiveIdInViewport = (props: { indexId: string }) => {
  const { indexId } = props;
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    const listenScrollEvent = (event: any) => {
      var element = document.getElementById(indexId);
      if (element) {
        var bounding = element.getBoundingClientRect();
        if (bounding.top >= 0) {
          setActive(true);
        } else {
          setActive(false);
        }
      }
    };

    window.addEventListener('scroll', listenScrollEvent);
    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, [indexId]);

  return { active };
};

export const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes == 0) return 'n/a';

  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  if (i === 0) return `${bytes} ${sizes[i]}`;

  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const clampText = (element: HTMLElement, maxLines: number): void => {
  const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
  const maxHeight = maxLines * lineHeight;

  if (element.scrollHeight <= maxHeight) {
    return;
  }

  let text = element.textContent || '';
  while (text.length > 0 && element.scrollHeight > maxHeight) {
    text = text.slice(0, -1);
    element.textContent = text + '…';
  }
};

export const ReColorSVG = (svg: string, fromColor: string, toColor: string) => {
  return svg.replaceAll(
    fromColor.replaceAll('#', '%23'),
    toColor.replaceAll('#', '%23')
  );
};

export const formatIDR = (value: number, lang?: string) =>
  Intl.NumberFormat(lang ? lang : 'en-US', {
    style: 'currency',
    currency: 'IDR',
  }).format(Math.ceil(value));

export const formatDate = (
  value: Date,
  dateStyle?: 'full' | 'long' | 'medium' | 'short',
  timeStyle?: 'full' | 'long' | 'medium' | 'short',
  lang?: string
) =>
  Intl.DateTimeFormat(lang ? lang : 'en-US', {
    dateStyle: dateStyle ?? 'medium',
    timeStyle: timeStyle ?? 'medium',
  }).format(value);

export const validateSolanaAddress = (addr: string) => {
  try {
    const address = new PublicKey(addr);
    return PublicKey.isOnCurve(address);
  } catch (error) {
    return false;
  }
};

export const validateSolanaAddressAccountWithBalance = async (
  address: string,
  connectionUrl: string
) => {
  if (validateSolanaAddress(address)) {
    try {
      const pubKey = new PublicKey(address);
      const provider = new Connection(connectionUrl);
      const balance = await provider.getBalance(pubKey);

      if (balance) return true;
      else return false;
    } catch (error) {
      return false;
    }
  } else return false;
};

export const parseSOLTransactionDetail = (
  transaction: ParsedTransactionWithMeta | null
) => {
  let totalSOLAmount = 0;
  let seller: string | undefined;
  let buyer: string | undefined;
  let transactionType: string | undefined;
  let NFTAddress: string | undefined;

  // get all program log instruction message
  const logMessage = transaction?.meta?.logMessages ?? [];
  const filteredMessages = logMessage.filter((message) =>
    message.includes('Program log: Instruction: ')
  );
  const removeUneededMessage = filteredMessages.map((msg) =>
    msg.replace('Program log: Instruction: ', '')
  );

  let executeSellIndex = -1;

  // set transaction type base log message
  if (removeUneededMessage.includes('Sell')) {
    transactionType = 'Listing';
  } else if (removeUneededMessage.includes('Mint')) {
    transactionType = 'Mint';
  } else if (removeUneededMessage.includes('Cancel')) {
    transactionType = 'Cancel Listing';
  } else if (removeUneededMessage.includes('Buy')) {
    executeSellIndex = removeUneededMessage.indexOf('ExecuteSale');
    transactionType = 'Sale';
  } else {
    transactionType = '-';
  }

  // get amount of SOL per transaction
  const innerInstructions = transaction?.meta?.innerInstructions ?? [];
  const instructionWithLamport: ParsedInstruction[] = innerInstructions.flatMap(
    (innerTx) => {
      return innerTx.instructions.map((instruction) => {
        const parsedInstruction: ParsedInstruction =
          instruction as ParsedInstruction;

        if (
          parsedInstruction.parsed?.info?.lamports &&
          parsedInstruction.parsed.type &&
          parsedInstruction.parsed?.type === 'transfer'
        ) {
          if (transactionType === 'Sale') {
            if (innerTx.index === 0) {
              return parsedInstruction;
            }
          } else {
            return parsedInstruction;
          }
        }

        // set seller on listing instruction
        if (
          parsedInstruction.programId.toBase58() ===
            TOKEN_PROGRAM_ID.toBase58() &&
          parsedInstruction.parsed.type
        ) {
          if (parsedInstruction.parsed.type === 'approve') {
            seller = parsedInstruction.parsed.info.owner;
          }
          if (parsedInstruction.parsed.type === 'initializeAccount3') {
            buyer = parsedInstruction.parsed.info.owner;
            NFTAddress = parsedInstruction.parsed.info.mint;
          }
        }
      }) as ParsedInstruction[];
    }
  );

  totalSOLAmount = instructionWithLamport
    .filter((f) => f)
    .reduce((total, item) => total + item.parsed.info.lamports, 0);

  // set transaction type if SOL Transfer
  const instructions = transaction?.transaction.message.instructions;
  if (instructions?.length === 1) {
    const parsedInstruction: ParsedInstruction = transaction?.transaction
      .message.instructions[0] as ParsedInstruction;

    if (
      parsedInstruction.parsed &&
      parsedInstruction.parsed.type &&
      parsedInstruction.parsed.type === 'transfer' &&
      parsedInstruction.parsed.info.lamports
    ) {
      transactionType = 'SOL Transfer';
      totalSOLAmount = parsedInstruction.parsed.info.lamports;
      seller = parsedInstruction.parsed.info.source;
      buyer = parsedInstruction.parsed.info.destination;
    }
  }
  // for !== SOL Transfer
  else {
    if (instructions) {
      const parsedInstruction: PartiallyDecodedInstruction = transaction
        ?.transaction.message.instructions[
        instructions.length - 1
      ] as PartiallyDecodedInstruction;

      // set seller on mint type
      if (parsedInstruction.accounts.length > 14) {
        seller = parsedInstruction.accounts[13].toString();
      }

      // plan A for get Seller based on authority
      if (transactionType === 'Sale') {
        seller = parsedInstruction.accounts[3].toString();
      }

      // plan B for get Seller based on executeSell message log
      if (executeSellIndex > -1) {
        const parsedInstructionForExecuteSell: PartiallyDecodedInstruction =
          transaction?.transaction.message.instructions[
            executeSellIndex
          ] as PartiallyDecodedInstruction;
        buyer = parsedInstructionForExecuteSell.accounts[0].toString();
        seller = parsedInstructionForExecuteSell.accounts[1].toString();
      }
    }
  }

  // set NFT Address
  const postTokenBalances = transaction?.meta?.postTokenBalances;
  postTokenBalances?.map((pstb) => {
    if (pstb.mint) {
      NFTAddress = pstb.mint;

      if (transactionType === 'Cancel Listing') {
        seller = pstb.owner;
      }
    }
  });

  return { transactionType, seller, buyer, NFTAddress, totalSOLAmount };
};

export const S3URL = `https://${awsExport.aws_user_files_s3_bucket}.s3.${awsExport.aws_user_files_s3_bucket_region}.amazonaws.com/`;

export const uploadImageToS3 = ({
  image,
  imageName,
  prefix,
}: {
  image: File;
  imageName?: string;
  prefix: string;
}) =>
  Storage.put(
    imageName
      ? `${imageName}.${image.type.replace(/(.*)\//g, '')}`
      : image.name,
    image,
    {
      level: 'public',
      contentType: image.type,
      acl: 'public-read',
      customPrefix: {
        public: prefix,
      },
    }
  );

export const snapSrcUrl =
  process.env.NEXT_PUBLIC_AWS_EXPORT_TYPE === 'PROD'
    ? 'https://app.midtrans.com/snap/snap.js'
    : 'https://app.sandbox.midtrans.com/snap/snap.js';

export const removeUnderscore = (value: string) => value.replace(/_/g, ' ');
