/* eslint-disable react/jsx-no-target-blank */
import { useState, useEffect } from "react";

import { CSSProperties } from "react";
import {
  Box,
  chakra,
  ChakraProps,
  Icon,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiCloseFill, RiMenu4Fill, RiMenuFill } from "react-icons/ri";

export const Logo = () => {
  return (
    <Image
      width={{ base: 24, md: 28 }}
      marginTop={6}
      src="/images/logo.png"
      alt="NFT One"
    />
  );
};

export const LogoWithText = () => {
  return (
    <Image
      width={{ base: 24, md: 28 }}
      marginTop={6}
      src="/images/logo-text.png"
      alt="NFT One"
    />
  );
};

interface LogoWithTextLongProps {
  className?: string;
  logoColor?: "black" | "white";
}

export const LogoWithTextLong = ({ logoColor }: LogoWithTextLongProps) => {
  const imageUrlColor = useColorModeValue(
    "/images/logo-text-long-black.png",
    "/images/logo-text-long.png"
  );

  const imageUrl = logoColor
    ? logoColor == "black"
      ? "/images/logo-text-long-black.png"
      : "/images/logo-text-long.png"
    : imageUrlColor;

  return <Image width={{ base: 40, md: 44 }} src={imageUrl} alt="NFT One" />;
};

// Hamburger Animation Icon
export const Hicon = ({ isOpen = false }: { isOpen: boolean }) => {
  const XIcon: CSSProperties = {
    transform: isOpen ? "scale(1)" : "scale(0)",
    transition: isOpen ? "transform 400ms 250ms" : "transform 400ms",
  };
  const SvgPos: CSSProperties = {
    position: "absolute",
    margin: "auto",
    top: 0,
    right: 0,
    bottom: 0,
    left: "-12px",
  };
  const SvgStyle: CSSProperties = {
    width: "70px",
    height: "70px",
  };
  const strokeDashOpen: number = isOpen ? 5 : 21;
  const SVG: { [key: string]: CSSProperties } = {
    stroke: {
      strokeWidth: "5px",
    },
    line: {
      fill: "none",
      stroke: isOpen
        ? "var(--chakra-colors-main-purple)"
        : "var(--chakra-colors-white)",
      strokeWidth: "5px",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      transformOrigin: "50%",
      transition:
        "stroke-dasharray 500ms 200ms, stroke-dashoffset 500ms 200ms, transform 500ms 200ms, stroke 500ms 200ms",
    },
    line1: {
      strokeDasharray: `${strokeDashOpen} 185.62753295898438`,
      strokeDashoffset: isOpen ? "-141px" : "0px",
      transitionDelay: "0ms",
    },
    line2: {
      strokeDasharray: `${strokeDashOpen} 178.6514129638672`,
      strokeDashoffset: isOpen ? "-137px" : "0px",
      transitionDelay: "30ms",
    },
    line3: {
      strokeDasharray: `${strokeDashOpen} 197.92425537109375`,
      strokeDashoffset: isOpen ? "-176px" : "0px",
      transitionDelay: "60ms",
    },
    line4: {
      strokeDasharray: `${strokeDashOpen} 190.6597137451172`,
      strokeDashoffset: isOpen ? "-159px" : "0px",
      transitionDelay: "90ms",
    },
    line5: {
      strokeDasharray: `${strokeDashOpen} 208.52874755859375`,
      strokeDashoffset: isOpen ? "-139px" : "0px",
      transitionDelay: "120ms",
    },
    line6: {
      strokeDasharray: `${strokeDashOpen} 186.59703063964844`,
      strokeDashoffset: isOpen ? "-176px" : "0px",
      transitionDelay: "150ms",
    },
  };

  if (isOpen) {
    return <Icon boxSize={5} as={RiCloseFill} />;
  } else {
    return <Icon boxSize={5} as={RiMenuFill} />;
  }
};

export const SolanaIcon = () => {
  return (
    <svg fill="none" viewBox="0 0 53 41" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 26.75L1.5 14.25H39L51.5 26.75M39 14.25L51.5 1.75H14L1.5 14.25M1.5 39.25H39L51.5 26.75H14L1.5 39.25Z"
        stroke="url(#a)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <defs>
        <linearGradient
          id="a"
          x1="26.5"
          x2="26.5"
          y1="1.75"
          y2="39.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C51FA0" offset="0" />
          <stop stopColor="#1BB2DE" offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const IDRIcon = (props: ChakraProps) => (
  <chakra.span
    fontSize={12}
    lineHeight={1}
    p={"0.25rem"}
    rounded={"sm"}
    borderWidth={"1px"}
    borderColor={"gray.50"}
    {...props}
  >
    IDR
  </chakra.span>
);
